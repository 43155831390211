import { lerp } from '@/helpers/lerp';
import { getPositionByDirection } from './get-position-by-direction';
import { LINE_GRADATION_DIRECTIONS } from './line-gradation.constants';

type CreateLinesProps = {
  amount: number;
  direction: `${LINE_GRADATION_DIRECTIONS}`;
  width: number;
  height: number;
  svgWidth: number;
  svgHeight: number;
};

export const createLines = ({ amount, direction, width, height, svgWidth, svgHeight }: CreateLinesProps) => {
  const minSize = 2;

  const isXAxis = direction === LINE_GRADATION_DIRECTIONS.left || direction === LINE_GRADATION_DIRECTIONS.right;

  const size = isXAxis ? width : height;

  const totalCombinedLineSize = size / 2 + minSize - size / amount;

  const evenlySizedAmount = totalCombinedLineSize / amount;

  // TODO: get this right... it's close enough that it works but is not exact.
  const startSize = evenlySizedAmount + (evenlySizedAmount - minSize);

  const endWidth = minSize;

  // TODO: optimize this
  const lines = new Array(amount).fill({}).map((_, i) => ({
    strokeWidth: lerp(startSize, endWidth, i / amount),
  }));

  const linesPositing = lines.map(({ strokeWidth }, i) => {
    const totalPrevOffX = lines.slice(0, i).reduce((a, c) => c.strokeWidth + a, 0);

    const totalSpaceOffX = lines.slice(lines.length - i).reduce((a, c) => c.strokeWidth + a, 0);

    // x is centered to the strokeWidth since were drawing lines not rects
    const val = strokeWidth / 2 + totalPrevOffX + totalSpaceOffX;

    return {
      strokeWidth,
      ...getPositionByDirection({
        val,
        direction,
        svgWidth,
        svgHeight,
      }),
    };
  });

  return linesPositing;
};
