import { LINE_GRADATION_DIRECTIONS } from './line-gradation.constants';

type GetPositionByDirectionProps = {
  val: number;
  direction: `${LINE_GRADATION_DIRECTIONS}`;
  svgWidth: number;
  svgHeight: number;
};

export const getPositionByDirection = ({ val, direction, svgWidth, svgHeight }: GetPositionByDirectionProps) => {
  const getOffset = {
    [LINE_GRADATION_DIRECTIONS.left]: (x: number) => ({
      x1: x,
      x2: x,
      y1: 0,
      y2: svgHeight,
    }),
    [LINE_GRADATION_DIRECTIONS.right]: (x: number) => ({
      x1: svgWidth - x,
      x2: svgWidth - x,
      y1: 0,
      y2: svgHeight,
    }),
    [LINE_GRADATION_DIRECTIONS.up]: (y: number) => ({
      x1: 0,
      x2: svgWidth,
      y1: y,
      y2: y,
    }),
    [LINE_GRADATION_DIRECTIONS.down]: (y: number) => ({
      x1: 0,
      x2: svgWidth,
      y1: svgHeight - y,
      y2: svgHeight - y,
    }),
  };

  return getOffset[direction](val);
};
