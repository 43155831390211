import { Image } from '@/components/image';
import { assetUrl } from '@/helpers/asset-url';
import { ExternalAccount } from '@/hooks/use-external-accounts';
import clsx from 'clsx';
import React from 'react';

export function LinkedAccountsContent(props: {
  onClickDone: () => void;
  linkedAccounts: ExternalAccount[];
  title?: string;
}) {
  return (
    <div className="flex-1 h-full flex flex-col justify-between">
      <div className="">
        <p className="text-lg text-gray-950 leading-6 pb-6">{props.title || 'Account linked!'}</p>
        {props.linkedAccounts.map((linkedAccount) => (
          <div
            key={linkedAccount.id}
            className="border-gray-200 p-5 border-1 rounded-lg flex justify-between items-center mb-3"
          >
            <div className="flex items-center">
              {linkedAccount.plaidInstitutionLogo ? (
                <Image
                  alt={linkedAccount.plaidInstitutionName}
                  width={28}
                  height={28}
                  src={`data:image/png;base64,${linkedAccount.plaidInstitutionLogo}`}
                />
              ) : (
                <Image alt="A bank logo" src={assetUrl(`shared/icons/default_bank_icon.svg`)} width={25} height={25} />
              )}
              <p className="text-gray-950 test-base ml-2">{linkedAccount.externalAccountNickname}</p>
            </div>
            <span
              className={clsx('text-sm uppercase', {
                'text-green-600': linkedAccount.isVerified,
                'text-red-700': !linkedAccount.isVerified,
              })}
            >
              {linkedAccount.isVerified ? 'Verified' : 'Unverified'}
            </span>
          </div>
        ))}
      </div>
      <div className="flex justify-end mt-4 mb-4">
        <button
          type="button"
          className="outline-none sm:w-fit w-full px-4 py-2 text-center text-base border-2 rounded-md text-gray-100 bg-blue border-blue"
          onClick={props.onClickDone}
        >
          Done
        </button>
      </div>
    </div>
  );
}
