import { LineGradation } from '@/components/line-gradation';
import { LINE_GRADATION_DIRECTIONS } from '@/components/line-gradation/helpers/line-gradation.constants';
import React from 'react';

function LoadingImage() {
  return (
    <svg className="md:w-56 w-40" viewBox="0 0 160 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="72" rx="8" fill="#1EA1C1" />
      <rect x="12" y="12" width="28" height="28" rx="14" fill="white" />
      <path
        d="M23.3333 27.3333V29.3333M26 27.3333V29.3333M28.6667 27.3333V29.3333M20 32H32M20 24.6667H32M20 22.6667L26 20L32 22.6667M20.6667 24.6667H31.3333V32H20.6667V24.6667Z"
        stroke="#1EA1C1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 17C45.3431 17 44 18.3431 44 20C44 21.6569 45.3431 23 47 23H91C92.6569 23 94 21.6569 94 20C94 18.3431 92.6569 17 91 17H47ZM47 29C45.3431 29 44 30.3431 44 32C44 33.6569 45.3431 35 47 35H74C75.6569 35 77 33.6569 77 32C77 30.3431 75.6569 29 74 29H47Z"
        fill="white"
      />
      <rect x="115" y="54" width="6" height="6" rx="3" fill="white" />
      <rect x="124" y="54" width="6" height="6" rx="3" fill="white" />
      <rect x="133" y="54" width="6" height="6" rx="3" fill="white" />
      <rect x="142" y="54" width="6" height="6" rx="3" fill="white" />
    </svg>
  );
}

export function LoadingContent(props: {
  onClickClose: () => void;
  errorMessage?: string;
  title?: string;
  description?: string;
}) {
  return (
    <div className="flex-1 h-full flex flex-col justify-between">
      <div className="">
        <p className="text-lg text-gray-950 leading-6 pb-6">{props.title || 'Linking your account...'}</p>
        <div className="rounded-lg overflow-hidden relative">
          <LineGradation
            direction={LINE_GRADATION_DIRECTIONS.left}
            ratio={0.5}
            amount={10}
            shouldPulse
            lineColor="#00566B"
            backgroundColor="#1A2428"
            transitionDuration={2500}
            transitionDelay={250}
            className="transition-colors duration-500"
          />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
            <LoadingImage />
          </div>
        </div>
        <p className="text-gray-950 text-base font-normal leading-5 mt-8">
          {props.description || 'Feel free to leave this page and come back later, your account should appear soon.'}
        </p>
        <p className="text-gray-950 text-base font-normal leading-5 mt-7">
          If it doesn&apos;t - please try again or contact support
        </p>
        {!!props.errorMessage && (
          <>
            <p className="text-base font-normal text-red-700 mt-6">Something went wrong</p>
            <p className="text-base font-normal text-red-700 mt-1">{props.errorMessage}</p>
          </>
        )}
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="outline-none w-full sm:w-fit mt-3 sm:mt-0 px-4 py-2 text-center text-base text-gray-800 border-2 border-gray-200 rounded-md"
          onClick={props.onClickClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}
