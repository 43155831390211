import { LINE_GRADATION_DIRECTIONS } from './line-gradation.constants';

type GetDimensionsByDirectionProps = {
  size: number;
  ratio: number;
  padding: number;
  direction: `${LINE_GRADATION_DIRECTIONS}`;
};

export const getDimensionsByDirection = ({ size, ratio, padding, direction }: GetDimensionsByDirectionProps) => {
  const horizontal = () => {
    const w = size;
    const h = size * ratio;
    const pad = w * padding;
    return {
      width: w,
      height: h,
      svgWidth: w + pad,
      svgHeight: h,
    };
  };

  const vertical = () => {
    const w = size;
    const h = size * ratio;
    const pad = h * padding;
    return {
      width: w,
      height: h,
      svgWidth: w,
      svgHeight: h + pad,
    };
  };

  const getDimensions = {
    [LINE_GRADATION_DIRECTIONS.left]: horizontal,
    [LINE_GRADATION_DIRECTIONS.right]: horizontal,
    [LINE_GRADATION_DIRECTIONS.up]: vertical,
    [LINE_GRADATION_DIRECTIONS.down]: vertical,
  };

  return getDimensions[direction]();
};
