import { LINE_GRADATION_DIRECTIONS } from './line-gradation.constants';

export const getTranslationByDirection = (val: number, direction: `${LINE_GRADATION_DIRECTIONS}`) => {
  const getOffset = {
    [LINE_GRADATION_DIRECTIONS.left]: (v: number) => `translateX(-${v}px)`,
    [LINE_GRADATION_DIRECTIONS.right]: (v: number) => `translateX(${v}px)`,
    [LINE_GRADATION_DIRECTIONS.up]: (v: number) => `translateY(-${v}px)`,
    [LINE_GRADATION_DIRECTIONS.down]: (v: number) => `translateY(${v}px)`,
  };

  return getOffset[direction](val);
};
