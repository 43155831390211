/**
 * Gets the linear interpretation given a start, end, and the current amount (0–1)
 * @param  {Number} start
 * @param  {Number} end
 * @param  {Number} amount value between 0-1
 * @returns {Number}
 */
export function lerp(start: number, end: number, amount: number) {
  return (1 - amount) * start + amount * end;
}
