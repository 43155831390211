import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_PLAID_UPDATE_MODE_TESTING } from '@/constants/features';
import {
  LinkType,
  useCreatePlaidLinkTokenMutation,
  useCreatePlaidLinkTokenWithoutTransactionsProductMutation,
  useExchangePlaidPublicItemTokenMutation,
} from '@/generated/graphql';
import { useCallback, useEffect, useState } from 'react';
import { PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link';

const LOCAL_STORAGE_KEY = '@hmbradley/plaid-link-token';

function useLinkToken(props: { isEnabled: boolean }) {
  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');
  const plaidUpdateModeTestingFeatureFlag = useFeatureWithUserStrategies(FEATURE_PLAID_UPDATE_MODE_TESTING);

  const createPlaidLinkTokenMutation = useCreatePlaidLinkTokenMutation({
    onSuccess(data) {
      if (data?.createPlaidLinkToken?.linkToken) {
        setToken(data.createPlaidLinkToken.linkToken);
        // Store link_token temporarily in case of OAuth redirect
        localStorage.setItem(LOCAL_STORAGE_KEY, data.createPlaidLinkToken.linkToken);
      }
    },
  });

  const createPlaidLinkTokenTestingMutation = useCreatePlaidLinkTokenWithoutTransactionsProductMutation({
    onSuccess(data) {
      if (data?.createPlaidLinkTokenWithoutTransactionsProduct?.linkToken) {
        setToken(data.createPlaidLinkTokenWithoutTransactionsProduct.linkToken);
        // Store link_token temporarily in case of OAuth redirect
        localStorage.setItem(LOCAL_STORAGE_KEY, data.createPlaidLinkTokenWithoutTransactionsProduct.linkToken);
      }
    },
  });

  useEffect(() => {
    // Do not generate a new token if page is handling an OAuth redirect.
    // instead, setLinkToken to previously generated token from localStorage
    // https://plaid.com/docs/link/oauth/#reinitializing-link
    if (isOAuthRedirect) {
      setToken(localStorage.getItem(LOCAL_STORAGE_KEY));
      return;
    }

    if (props.isEnabled) {
      if (plaidUpdateModeTestingFeatureFlag.isEnabled) {
        createPlaidLinkTokenTestingMutation.mutate({});
      } else {
        createPlaidLinkTokenMutation.mutate({
          linkType: LinkType.AddAccount,
        });
      }
    }
  }, [plaidUpdateModeTestingFeatureFlag.isEnabled, props.isEnabled]);

  return {
    isOAuthRedirect,
    token,
  };
}

export function useLinkExternalAccount(props: {
  onSuccess: () => void;
  onLinkFinished: () => void;
  onError: (message: string) => void;
  onExit: () => void;
  isEnabled: boolean;
}) {
  const linkToken = useLinkToken({ isEnabled: props.isEnabled });
  const exchangePlaidPublicItemTokenMutation = useExchangePlaidPublicItemTokenMutation();

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (publicToken, metadata) => {
    props.onLinkFinished();
    await exchangePlaidPublicItemTokenMutation.mutateAsync({
      publicToken,
      metadata: JSON.stringify(metadata || {}),
    });

    props.onSuccess();
  }, []);
  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    if (eventName === 'ERROR') {
      props.onError(metadata.error_message);
    }
  }, []);
  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    props.onExit();
  }, []);

  const plaidLink = usePlaidLink({
    token: linkToken.token,
    onSuccess,
    onEvent,
    onExit,
    receivedRedirectUri: linkToken.isOAuthRedirect ? window.location.href : undefined,
  });

  useEffect(() => {
    if (linkToken.isOAuthRedirect && plaidLink.ready) {
      plaidLink.open();
    }
  }, [plaidLink.ready, plaidLink.open, linkToken.isOAuthRedirect]);

  return { isOAuthRedirect: linkToken.isOAuthRedirect, open: plaidLink.open, ready: plaidLink.ready };
}
