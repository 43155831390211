import { easeInOutCubic } from '@/helpers/easing';
import COLORS from '@/styles/colors';
import cx from 'clsx';
import { createLines } from './helpers/create-lines';
import { getDimensionsByDirection } from './helpers/get-dimensions-by-direction';
import { getTranslationByDirection } from './helpers/get-translation-by-direction';
import { LINE_GRADATION_DIRECTIONS } from './helpers/line-gradation.constants';

type LineGradationProps = {
  amount?: number;
  backgroundColor?: string;
  className?: string;
  direction: `${LINE_GRADATION_DIRECTIONS}`;
  easingFunction?: (n: number) => number;
  isVisible?: boolean;
  lineColor?: string;
  minTranslation?: number;
  padding?: number;
  ratio?: number;
  shouldPulse?: boolean;
  size?: number;
  transitionDelay?: number;
  transitionDuration?: number;
};

export function LineGradation({
  amount = 5,
  backgroundColor = COLORS.pastel_green,
  className = '',
  direction = LINE_GRADATION_DIRECTIONS.left,
  easingFunction = easeInOutCubic,
  isVisible = true,
  lineColor = COLORS.white,
  minTranslation = 10,
  padding = 0,
  ratio = 1,
  shouldPulse = false,
  size = 500,
  transitionDelay = 50,
  transitionDuration = 300,
}: LineGradationProps) {
  const { width, height, svgWidth, svgHeight } = getDimensionsByDirection({
    direction,
    size,
    padding,
    ratio,
  });

  const lines = createLines({
    amount,
    direction,
    width,
    height,
    svgWidth,
    svgHeight,
  });

  const getDelay = (x: number, delay: number) => `${Math.round(easingFunction(x) * delay)}ms`;

  return (
    <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} className={className}>
      <rect x={0} y={0} width={svgWidth} height={svgHeight} fill={backgroundColor} />
      {lines.map(({ strokeWidth, x1, x2, y1, y2 }, i) => (
        <line
          className={cx('transition-all ease-in-out duration-500', {
            'animated-line': shouldPulse,
            'animate-none stroke-0': !isVisible,
          })}
          key={strokeWidth}
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke={lineColor}
          strokeWidth={strokeWidth}
          strokeLinecap="butt"
          style={
            {
              '--strokeWidth': strokeWidth,
              '--strokeWidthPulse': Math.max(strokeWidth - 6, 2),
              transitionDuration: `${transitionDuration}ms`,
              transitionDelay: getDelay(i / lines.length - 1, transitionDelay),
              animationDuration: `${transitionDuration}ms`,
              animationDelay: getDelay(i / lines.length - 1, transitionDelay),
              transform: !isVisible ? getTranslationByDirection(10 * i + minTranslation, direction) : '',
            } as React.CSSProperties
          }
        />
      ))}
    </svg>
  );
}
