import { Dialog } from '@headlessui/react';
import cx from 'clsx';
import React from 'react';

import { InitialContent } from '@/components/plaid-link-account-modal/components/initial-content';
import { LinkedAccountsContent } from '@/components/plaid-link-account-modal/components/linked-accounts-content';
import { FULLSTORY_UNMASK } from '@/constants';

import { LoadingContent } from '@/components/plaid-link-account-modal/components/loading-content';
import { ExternalAccount, useExternalAccounts } from '@/hooks/use-external-accounts';
import { useLinkExternalAccount } from '@/hooks/use-link-external-account';

let interval;

function usePlaidLinkAccountModal(props: { withInitialTrigger?: boolean }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLinkFinished, setIsLinkFinished] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [linkedAccounts, setLinkedAccounts] = React.useState<ExternalAccount[] | undefined>();
  const [existingAccounts, setExistingAccounts] = React.useState<ExternalAccount[] | undefined>();

  const externalAccounts = useExternalAccounts();

  function onCloseModal() {
    setIsModalOpen(false);
    setIsLinkFinished(false);
    setErrorMessage(undefined);
    clearInterval(interval);
  }

  function onOpenModal() {
    setIsModalOpen(true);
    setLinkedAccounts(undefined);
  }

  const linkExternalAccount = useLinkExternalAccount({
    onSuccess: () => {
      interval = setInterval(() => {
        externalAccounts.refetch();
      }, 1500);
    },
    onLinkFinished: () => {
      setIsLinkFinished(true);
    },
    onExit: () => {
      onCloseModal();
    },
    onError: (message: string) => {
      setErrorMessage(message);
    },
    isEnabled: isModalOpen,
  });

  function onClickLinkAccount() {
    linkExternalAccount.open();
  }

  React.useEffect(() => {
    if (externalAccounts.isFetched && externalAccounts.externalAccounts.length === 0 && props.withInitialTrigger) {
      onOpenModal();
    }
  }, [externalAccounts.isFetched]);

  React.useEffect(() => {
    if (isModalOpen && externalAccounts.isFetched) {
      setExistingAccounts(externalAccounts.externalAccounts);
    }
  }, [externalAccounts.isFetched, isModalOpen]);

  React.useEffect(() => {
    if (!!existingAccounts) {
      const newAccounts = externalAccounts.externalAccounts.filter(
        (account) => !existingAccounts.find((existingAccount) => account.id === existingAccount.id),
      );

      if (newAccounts.length > 0) {
        setLinkedAccounts(newAccounts);
        clearInterval(interval);
      }
    }
  }, [externalAccounts.externalAccounts.length]);

  return {
    isModalOpen,
    onCloseModal,
    onOpenModal,
    onClickLinkAccount,
    linkedAccounts,
    ready: linkExternalAccount.ready,
    isLinkFinished,
    errorMessage,
  };
}

export function PlaidLinkAccountModal(props: {
  cancelText?: string;
  children: React.ReactNode;
  onAccountLinked?: (metadata: ExternalAccount) => void;
  withInitialTrigger?: boolean;
}) {
  const controller = usePlaidLinkAccountModal({
    withInitialTrigger: props.withInitialTrigger,
  });

  React.useEffect(() => {
    if (controller.linkedAccounts) {
      props.onAccountLinked?.(controller.linkedAccounts[0]);
    }
  }, [controller.linkedAccounts]);

  return (
    <>
      <Dialog
        open={controller.isModalOpen}
        onClose={controller.onCloseModal}
        className={cx('fixed inset-0 flex items-center justify-center z-4000', FULLSTORY_UNMASK)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="bg-white z-10 w-4/5 md:w-1/2 lg:w-1/3 max-w-[500px] rounded-lg p-5 overflow-y-auto h-3/5">
          {!!controller.linkedAccounts ? (
            <LinkedAccountsContent onClickDone={controller.onCloseModal} linkedAccounts={controller.linkedAccounts} />
          ) : controller.isLinkFinished ? (
            <LoadingContent errorMessage={controller.errorMessage} onClickClose={controller.onCloseModal} />
          ) : (
            <InitialContent
              onClickStart={controller.onClickLinkAccount}
              onClickCancel={controller.onCloseModal}
              isReady={controller.ready}
              cancelText={props.cancelText}
              errorMessage={controller.errorMessage}
            />
          )}
        </div>
      </Dialog>
      <div role="button" onClick={controller.onOpenModal}>
        {props.children}
      </div>
    </>
  );
}
